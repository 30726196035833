import React from 'react';
import Helmet from 'react-helmet';
import { Container, Layout } from 'components';
import { withPrefix } from 'gatsby';
import styled from 'react-emotion';
import config from '../../config/website';

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display:flex;
  justify-content:center;
  align-items: center;
   @media (max-width: 1200px) {
    align-items: flex-start;
  }
  color: #011627;
`;

const Aboutcontainer = styled.div`
  display:flex;
  flex-direction:row;
  align-items: center;
  img {
    height: 300px;
    width: 300px;
    object-fit: cover;
    margin-right: 3em;
  }
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    margin-top:100px;
    img {
      margin: 0 0 2em 0;
    }
  }
`;

const About = () => (
  <Layout>
    <Helmet title={`About | ${config.siteTitle}`} />
    <Wrapper>
      <Container type="text">
        <Aboutcontainer>
            <img src={withPrefix('/img/aidenkwok.jpg')} alt="Aiden Kwok headshot" />
            <div>
              <h2>Hello there!</h2>
              <br/>
              <p> 
                I’m Aiden, a software developer, multidisciplinary designer, and recent graduate of graphic design at Edinburgh College of Art.
                I enjoy exploring the space between design and technology, conceptual and commercial. 
                My work can be a range of different things from web apps, mobile apps, photography, animation, branding, typography, sculpture and research. 
              </p>
              <br/>
              <p>
                When I’m not in the doing the things mentioned above, you can find me learning something new.
              </p>
            </div>
          </Aboutcontainer>
      </Container>
    </Wrapper>
  </Layout>
);

export default About;
